import React from 'react';
import './../css/about.css'
import Smallfoot from '../../component/Smallfoot';

const Credit = () => {
    return (
        <>
            <div className='creditContainer'>
                <div className='crArticles'>
                    <p className='crbig'>
                        제49회 야외조각전 준비위원회<br />
                    </p>
                    <p className='names'>
                        <br />김중균<br />김빈<br />조윤정<br />한지원<br />
                    </p>
                    <p className='crbig'>
                        <br />포스터 디자인<br />
                    </p>
                    <p className='names'>
                        <br />정상운<br />
                    </p>
                    <p className='crbig'>
                        <br />전시 참여 인원<br />
                    </p>
                    <p className='names'>
                        <br />고예진<br />김나영<br />김빈<br />김세하<br />김은서<br />김응준<br />김준우<br />김준형<br />김채연<br />남가현<br />류재헌<br />백자연<br />서성우<br />서지수<br />오유진<br />이시환<br />이정주<br />임소윤<br />임지민<br />장도휘<br />정서연<br />조윤정<br />조은채<br />조재형<br />최은지<br />한지원<br />한지윤<br />황서현<br />DI JINGXIAN<br />
                    </p>
                    <p className='crbig'>
                        <br />  웹 디자인<br />
                    </p>
                    <p className='names'>
                        <br />오다은<br />
                    </p>
                    <p className='crbig'>
                        <br /> 웹 개발<br />
                    </p>
                    <p className='names'>
                        <br />주윤환<br />정성훈<br />
                    </p>
                </div>
            </div>
            <Smallfoot />
        </>
    );
};

export default Credit;